  import Vue from 'vue';

  Vue.mixin({
  	methods: 
  	{
  		flash: function(message, type) {
  			let flashMessage = { message: message, type: type}
  			Bus.$emit('flash-message', flashMessage);
  		},
  		modal: function(content) {
  			// let flashMessage = { message: message, type: type}
  			// Bus.$emit('flash-message', flashMessage);
  		},
  	}
  })