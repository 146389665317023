<template>
  <div class="editor" v-bind:class="{ 
  'has-error': errors
}">
<label>{{name}}</label>
<div class="error" v-if="errors"><span v-for="error in errors">{{error}}</span></div>   
<editor-menu-bubble class="menububble" :keep-in-bounds="keepInBounds" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
  <div
  class="menububble"
  :class="{ 'is-active': menu.isActive }"
  :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
  >
  <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
    <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
    <button class="menububble__button menububble__remove-link" @click="setLinkUrl(commands.link, null)" type="button">
      link verwijderen
    </button>
    <button class="menububble__button menububble__add-link" @click="setLinkUrl(commands.link, linkUrl)" type="button">
      link toevoegen
    </button>
  </form>

  <template v-else>
    <button
    class="menububble__button"
    :class="{ 'is-active': isActive.bold() }"
    @click="commands.bold"
    >
    bold
  </button>
  <button
  class="menububble__button"
  :class="{ 'is-active': isActive.italic() }"
  @click="commands.italic"
  >
  italic
</button>
<button
class="menububble__button"
@click="showLinkMenu(getMarkAttrs('link'))"
:class="{ 'is-active': isActive.link() }"
>
link
</button>
</template>

</div>
</editor-menu-bubble>
<editor-content :editor="editor" />
</div>
</template>

<script>
  import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
  import {
    Placeholder,
    Bold,
    Italic,
    Link,
    HardBreak,
  } from 'tiptap-extensions'

  export default {
    data()
    {
      return {
        linkUrl: null,
        linkMenuIsActive: false,
        keepInBounds: true,
        editor: new Editor({
          extensions: [
          new Link({ openOnClick: false}),
          new Bold(),
          new Italic(),
          new HardBreak(),
          new Placeholder({
            showOnlyCurrent: false,
            emptyNodeText: 'Hier typen..'
          }),
          ],
          onUpdate: ({ getHTML }) => {
            this.value = getHTML();
            this.$emit('input', this.value);
          },
          onFocus: () => {
            this.$emit('onFocus', this.name);
          },
        }),
      }
    },
    created() {
      if(this.default)
      {
        this.editor.setContent(this.default);
      }
    },
    methods:
    {
      showLinkMenu(attrs) {
        this.linkUrl = attrs.href
        this.linkMenuIsActive = true
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      },
      hideLinkMenu() {
        this.linkUrl = null
        this.linkMenuIsActive = false
      },
      setLinkUrl(command, url) {
        command({ href: url })
        this.hideLinkMenu()
      },

    },
    beforeDestroy() {
    },
    components: {
      EditorContent,
      EditorMenuBubble,
    },
    props:
    ['name','errors','default']
  }
</script>


<style lang="scss">


</style>