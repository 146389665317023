<template>
	<transition name="slide-fade">
		<div v-if="message" :class='classes'>
			{{ message }}
		</div>
	</transition>
</template>

<script>
	export default
	{
		data(){
			return {
				message: '',
				type: '',
			}
		},
		mounted() {
			let timer;
			Bus.$on('flash-message', (flash) => {
				clearTimeout(timer);
				this.message = flash.message;
				this.type = flash.type;
				timer = setTimeout(() => {
					this.message = '';
					this.type = '';
				}, 2000);

			});
		},
		computed:
		{
			classes(){
				return 'flash-message '+this.type;
			}
		}
	}
</script>

<style scoped>
	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.4s;
	}
	.slide-fade-enter
	{
		transform: translate(-50%,10px);
		opacity: 0;
	}
	.slide-fade-leave-to {
		transform: translate(-50%,10px);
		opacity: 0;
	}
</style>