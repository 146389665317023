 <template>
 	<div  v-bind:class="{ 
 	'in-focus': focus,
 	'has-error': allErrors
 }">
 <label for="titel">{{ name }}</label>
 <div class="file-uploader" :class='{ loading : loading }'>
 	<button  tabindex="0" @focus="onFocus()" @focusout="outFocus()" @click="uploadFile()" class="upload medium">{{ url ? 'Kies ander bestand' : 'Kies bestand'}}</button>
 	<div class="preview" v-if="url">
 		<img v-if="type === 'image'" :src="url" alt="">
 		<video v-else-if="type === 'video'" class="preview" :src="url"></video>	
 	</div> 			
 </div>
 <div class="underline"></div>
 <div class="error" v-if="allErrors"><span>{{allErrors}}</span></div>   

</div>
</template>

<script>
	export default
	{
		data(){
			return {
				focus: false,
				isActive: true,
				file: null,
				url:null,
				error:'',
				id:''

			};
		},
		watch:{
			id(val){
				this.$emit('input',val);				
			},
			defaultFile: function()
			{
				this.url = this.defaultFile.url;
				this.$emit('input',this.defaultFile.id);				
			}
		},
		computed:{
			allErrors: function()
			{
				let errors = this.errors ? this.errors.concat(this.error).join(' ') : this.error;
				return errors; 
			}
		},

		methods:{
			onFocus(){
				this.focus = true;
				this.$emit('onFocus',this.name);
			},
			outFocus()
			{
				this.focus = false;
			},
			uploadFile(){
				this.onFocus();
				var input = document.createElement('input');
				input.type = 'file';
				input.onchange = e => {
					this.clear();					
					this.file = e.target.files[0];
					let isCorrectFileType = this.file.type.includes(this.type);
					if (isCorrectFileType)
					{
						this.url = URL.createObjectURL(this.file);
						this.$emit('fileSelected',this);
						this.error = '';					
					}
					else
					{
						this.error = "Verkeerd bestandstype. Probeer een ander."
						this.url = '';
						this.file = null;
					}
				}
				input.click();
			},
			clear(){
				this.file = this.url =  null;
			},	
		},
		props:
		['name','value','type','errors','defaultFile', 'loading']
	}
</script>