const Projecten = () => import('./components/views/Projecten');
const NieuwProject = () => import('./components/views/NieuwProject');
const Project = () => import('./components/views/Project');
const Login = () => import('./components/views/Login');

export default
{
	mode: 'history',
	routes: [
		{
		path: '/control/login',
		component: Login,
		name: "Login"
	},
	{
		path: '/control/nieuw',
		component: NieuwProject,
		name: "Nieuw Project"
	},
	{
		path: '/control/',
		component: Projecten,
		name: 'Projecten',
	},
	{
		path: '/control/:id',
		component: Project,
		name: 'Project'
	},
	]
}