import Vue from 'vue';

import TextInput from './components/form/TextInput.vue';
import SelectInput from './components/form/SelectInput.vue';
import TextEditor from './components/form/TextEditor.vue';
import FileUploader from './components/form/FileUploader.vue';
import FlashMessage from './components/FlashMessage.vue';
import Navigation from './components/Navigation.vue';

Vue.component('text-input', TextInput);
Vue.component('text-editor', TextEditor);
Vue.component('select-input', SelectInput);
Vue.component('file-uploader', FileUploader);
Vue.component('flash-message', FlashMessage);
Vue.component('navigation', Navigation);
