<template>
	<div  v-click-outside="closeEvent" v-bind:class="[{ 
	'in-focus': focus},
	{'has-error': errors}]
	">
	
	<label>{{ name }}</label>

	<div 
		tabindex="0" 
		@focus="onFocus()" 
		@focusout="outFocus()"
		@keyup.enter="toggle()"
		class="custom-select" 
		v-bind:class="{ 'is-open': showOptions }">
	<div class="current" 	@click="toggle()" v-bind:class="{ 
	'is-placeholder': !(selectedOption) }">{{ display }} </div>

	<ul v-if='showOptions' class="options">
		<li 
		tabindex="0" 
		:class="{ 'is-selected' : option === selectedOption  }" 
		@click="select(option)" 
		@keyup.enter="selectedOption = option"
		v-for="(option, index) in options"  
		:key='index' 
		:id='option.id'>
		{{ option.titel || ''}}
		</li>

	<slot></slot>
</ul>

</div>
<div class="underline"></div>
<div class="error" v-if="errors"><span v-for="error in errors">{{error}}</span></div>

</div>
</template>

<script>
	import clickOutside from '../../helpers/directives';

	export default
	{
		data(){
			return {
				showOptions:false,
				focus: false
			}
		},
		mounted() {
			console.log('select mounted!');
			this.showOptions = false;
		},
		watch: {
			value: function()
			{
				this.selectById(this.value);				
			}

		},
		computed: {
			display: function ()
			{
				return this.selectedOption ? this.selectedOption.titel : 'Selecteer optie'
			},
			selectedOption: function() {
				if(this.value) {
					return this.options.find(element => element.id === this.value);
				}
			}
		},
		methods: {
			selectById(id) {
				this.showOptions = false;
			},
			toggle() {
				this.showOptions = ! this.showOptions;
				this.$emit('onFocus',this.name);

			},
			select(option){
				this.$emit('input', option.id);
			},
			onFocus()
			{
				this.focus = true;
				this.$emit('onFocus',this.name);
			},
			outFocus()
			{
				this.focus = false;
			},
			closeEvent(event)
			{
				if(this.showOptions){
					this.showOptions = false;
				}
			}
		},
		props:
		['name','value','options','errors']
	}
</script>