 <template>
 	<div  v-bind:class="{ 
 	'in-focus': focus,
 	'has-error': errors
 }">
 <label for="titel">{{ name }}</label>
 <input @focus="onFocus()" @blur="focus = false" type="text" v-model="inputValue" :placeholder="name">
 <div class="underline"></div>
 <div class="error" v-if="errors"><span v-for="error in errors">{{error}}</span></div>
</div>
</template>

<script>
	export default
	{
		data(){
			return {
				focus: false,
				isActive: true,
				hasError: false
			};
		},
		methods: {
			onFocus: function(){
				this.focus = true;
				this.$emit('onFocus',this.name);
			}
		},
		computed: {
			inputValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			},
		},
			props:
			['name','value','errors']
		}
	</script>