var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor",class:{ 
  'has-error': _vm.errors
}},[_c('label',[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.errors)?_c('div',{staticClass:"error"},_vm._l((_vm.errors),function(error){return _c('span',[_vm._v(_vm._s(error))])}),0):_vm._e(),_vm._v(" "),_c('editor-menu-bubble',{staticClass:"menububble",attrs:{"keep-in-bounds":_vm.keepInBounds,"editor":_vm.editor},on:{"hide":_vm.hideLinkMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; bottom: " + (menu.bottom) + "px;"))},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"menububble__input",attrs:{"type":"text","placeholder":"https://"},domProps:{"value":(_vm.linkUrl)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideLinkMenu($event)},"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"menububble__button menububble__remove-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, null)}}},[_vm._v("\n      link verwijderen\n    ")]),_vm._v(" "),_c('button',{staticClass:"menububble__button menububble__add-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_vm._v("\n      link toevoegen\n    ")])]):[_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.bold() },on:{"click":commands.bold}},[_vm._v("\n    bold\n  ")]),_vm._v(" "),_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.italic() },on:{"click":commands.italic}},[_vm._v("\n  italic\n")]),_vm._v(" "),_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.link() },on:{"click":function($event){_vm.showLinkMenu(getMarkAttrs('link'))}}},[_vm._v("\nlink\n")])]],2)]}}])}),_vm._v(" "),_c('editor-content',{attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }