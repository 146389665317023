/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 require('./bootstrap');

 import Vue from 'vue';
 import VueRouter from 'vue-router';
 import routes from './routes';

 window.Bus = new Vue();
 Vue.use(VueRouter); 



 
 require('./componentLoader');
 require('./mixins');


 
 const app = new Vue({
 	el: '#app',
 	router: new VueRouter(routes),

 });
